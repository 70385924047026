import { HttpErrorResponse } from '@angular/common/http'
import { createReducer, on } from '@ngrx/store'

import { setError, setIsLoading } from '~store/utils.store'

import { Insight, InsightsOverview, LinkedinOrganization, Post } from '../models'
import * as LinkedinOrganizationActions from './linkedin-organization.actions'

export const linkedinOrganizationFeatureKey = 'linkedinOrganization'

export interface State {
  activePost: Post
  error: HttpErrorResponse
  insights: Insight[]
  insightsOverview: InsightsOverview
  isLoading: boolean
  linkedinOrganization: LinkedinOrganization
  previousInsights: Insight[]
}

export const initialState: State = {
  activePost: null,
  error: null,
  insights: [],
  insightsOverview: null,
  isLoading: false,
  linkedinOrganization: null,
  previousInsights: [],
}

const setInsights = (state, action): State => ({
  ...state,
  isLoading: false,
  insights: action.payload.data.slice(action.payload.data.length / 2),
  previousInsights: action.payload.data.slice(0, action.payload.data.length / 2),
  error: null,
})

const setInsightsOverview = (state, action): State => ({
  ...state,
  insightsOverview: action.payload.data,
  error: null,
})

const setPosts = (state, action): State => ({
  ...state,
  isLoading: false,
  posts: action.payload.data,
  error: null,
})

const setActivePost = (state, action): State => ({
  ...state,
  activePost: {
    ...action.payload.data,
  },
  postsIsLoading: false,
  error: null,
})

const unsetActivePost = (state): State => ({
  ...state,
  activePost: null,
})

const resetState = (): State => initialState

export const reducer = createReducer(
  initialState,

  on(
    LinkedinOrganizationActions.setLinkedinOrganization,
    (state, action): State => ({
      ...state,
      linkedinOrganization: action.payload,
    }),
  ),

  on(LinkedinOrganizationActions.loadInsights, LinkedinOrganizationActions.loadPost, setIsLoading()),

  on(
    LinkedinOrganizationActions.loadInsightsError,
    LinkedinOrganizationActions.loadInsightsOverviewError,
    LinkedinOrganizationActions.loadPostError,
    setError,
  ),

  on(LinkedinOrganizationActions.loadInsightsSuccess, setInsights),

  on(LinkedinOrganizationActions.loadInsightsOverviewSuccess, setInsightsOverview),

  on(LinkedinOrganizationActions.loadPostsSuccess, setPosts),

  on(LinkedinOrganizationActions.loadPostSuccess, setActivePost),

  on(LinkedinOrganizationActions.unsetActivePost, unsetActivePost),

  on(LinkedinOrganizationActions.resetState, resetState),
)

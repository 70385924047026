import { createAction, props } from '@ngrx/store'
import { ProjectDateRangeRequest } from 'src/app/common/components/filters/date-range/date-range.model'

import { JsonResponse } from '~core/models/json-response.model'
import { createHTTPActions } from '~store/utils.actions'

import { Insight, InsightsRequest, LinkedinOrganization, Post, PostRequest, PostsRequest } from '../models'

export const setLinkedinOrganization = createAction(
  '[Linkedin Organization] Set LinkedinOrganization',
  props<{ payload: LinkedinOrganization }>(),
)

export const unsetActivePost = createAction('[Linkedin Organization] Unset Active Post')

export const [loadInsights, loadInsightsSuccess, loadInsightsError] = createHTTPActions<
  InsightsRequest,
  JsonResponse<Insight[]>
>('[Linkedin Organization] Load Insights')

export const [loadInsightsOverview, loadInsightsOverviewSuccess, loadInsightsOverviewError] = createHTTPActions<
  ProjectDateRangeRequest,
  JsonResponse<unknown>
>('[Linkedin Organization] Load Insights Overview')

export const [loadPosts, loadPostsSuccess, loadPostsError] = createHTTPActions<PostsRequest, JsonResponse<Post[]>>(
  '[Linkedin Organization] Load Posts',
)

export const [loadPost, loadPostSuccess, loadPostError] = createHTTPActions<PostRequest, JsonResponse<Post>>(
  '[Linkedin Organization] Load Post',
)

export const resetState = createAction('[Linkedin Organization] Reset state')

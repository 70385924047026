import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, exhaustMap, map } from 'rxjs/operators'

import { withPreviousPeriod } from '~core/utils/api'

import { LinkedinOrganizationApiService } from '../services/linkedin-organization-api.service'
import * as LinkedinOrganizationActions from './linkedin-organization.actions'

@Injectable()
export class LinkedinOrganizationEffects {
  loadInsights$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LinkedinOrganizationActions.loadInsights),
      exhaustMap(({ payload }) => {
        return this.linkedinOrganizationApiService.insights(withPreviousPeriod(payload)).pipe(
          map((insights) => LinkedinOrganizationActions.loadInsightsSuccess(insights)),
          catchError((err: HttpErrorResponse) => of(LinkedinOrganizationActions.loadInsightsError(err))),
        )
      }),
    )
  })

  loadInsightsOverview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LinkedinOrganizationActions.loadInsightsOverview),
      exhaustMap(({ payload }) => {
        return this.linkedinOrganizationApiService.insightsOverview(payload).pipe(
          map((insights) => LinkedinOrganizationActions.loadInsightsOverviewSuccess(insights)),
          catchError((err: HttpErrorResponse) => of(LinkedinOrganizationActions.loadInsightsOverviewError(err))),
        )
      }),
    )
  })

  loadPost$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LinkedinOrganizationActions.loadPost),
      exhaustMap(({ payload }) => {
        return this.linkedinOrganizationApiService.post(payload).pipe(
          map((post) => LinkedinOrganizationActions.loadPostSuccess(post)),
          catchError((err: HttpErrorResponse) => of(LinkedinOrganizationActions.loadPostError(err))),
        )
      }),
    )
  })

  constructor(
    private actions$: Actions,
    private linkedinOrganizationApiService: LinkedinOrganizationApiService,
  ) {}
}

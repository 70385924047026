import { ApplicationConfig } from '@angular/core'
import { provideTranslocoScope } from '@jsverse/transloco'
import { provideEffects } from '@ngrx/effects'
import { provideState } from '@ngrx/store'

import { translocoScopeLoader } from '~core/utils'

import { LinkedinOrganizationEffects } from './store/linkedin-organization.effects'
import * as fromLinkedinOrganization from './store/linkedin-organization.reducer'

export const inOrganizationConfig: ApplicationConfig['providers'] = [
  provideState(fromLinkedinOrganization.linkedinOrganizationFeatureKey, fromLinkedinOrganization.reducer),
  provideEffects(LinkedinOrganizationEffects),
]

export const inOrganizationI18nConfig: ApplicationConfig['providers'] = [
  // Provided via translationsConfig in app.config
  provideTranslocoScope({
    scope: 'linkedinOrganization',
    loader: translocoScopeLoader((lang: string, root: string) => import(`./${root}/${lang}.json`)),
  }),
]
